@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background: #3a4f41;
  font-family: "Kanit", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #e0a458;
}

.enlacho {
  color: #ecc798;
  font-family: "Kanit", sans-serif;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: rgb(182, 162, 7);
}

.footerMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10rem;
  position: fixed;
  left: 0%;
  right: 0%;
  bottom: 5%;
}

.footerMenuInner {
  width: 679px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

img {
  border-radius: 100%;
  width: 25px;
  height: 25px;
}

/* Popup container */
.popup {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

/* The actual popup (appears on top) */
.popup .popuptext {
  visibility: hidden;
  width: 160px;
  background-color: #468c98;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%;
  left: 50%;
  margin-left: -80px;
}

/* Popup arrow */
.popup .popuptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.popup .show {
  visibility: visible;
}

.innerPopup {
  margin: 0%;
  color: black;
  font-size: small;
}

.container {
  margin-left: 25%;
  margin-right: 25%;
  margin-bottom: 60px;
}

.footerMenu {
  position: fixed;
  bottom: 10px;
  left: 0;
  width: 100%;
  background-color: #3a4f41;
}

.centered {
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

.cta {
  border: none;
  background: none;
}

.cta span {
  padding-bottom: 7px;
  letter-spacing: 4px;
  font-size: 14px;
  padding-right: 15px;
  text-transform: uppercase;
}

.cta svg {
  transform: translateX(-8px);
  transition: all 0.3s ease;
}

.cta:hover svg {
  transform: translateX(0);
}

.cta:active svg {
  transform: scale(0.9);
}

.hover-underline-animation {
  position: relative;
  color: #e0a458;
  padding-bottom: 20px;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: #000000;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.cta:hover .hover-underline-animation:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.url {
  color: #ecc798;
  font-family: "Kanit", sans-serif;
  text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: rgb(182, 162, 7);
}
